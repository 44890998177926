<template>
  <el-dialog
      title="新增系统"
      :close-on-click-modal="true"
      :visible.sync="addSystemDialog"
      v-if="addSystemDialog"
      @close="closeDialog"
      width="50%"
      append-to-body>

    <el-steps :space="200" :active="activeIndex-0" finish-status="success" align-center>
      <el-step title="所属类别"></el-step>
      <el-step title="填写系统信息"></el-step>
      <el-step title="确认"></el-step>
    </el-steps>

    <el-form :label-position="'top'" label-width="100%" :rules="rules" ref="addForm" :model="addForm">
      <el-tabs :tab-position="'left'" v-model="activeIndex">
        <el-tab-pane label="所属类别" name="0">
          <el-form-item label="类别名称" prop="coalId">
<!--            <el-select v-model="addForm.coalId" placeholder="请选择">-->
<!--              <el-option v-for="item in options" :key="item.id" :label="item.coal" :value="item.id"></el-option>-->
<!--            </el-select>-->
            <el-input v-model="global.coal_name" readonly="true" style="width: 300px"></el-input>
          </el-form-item>

        </el-tab-pane>
        <el-tab-pane label="填写系统信息" name="1">
          <el-form-item label="系统名称" prop="systemName">
            <el-input style="width: 45%;margin-bottom: 5px" v-model="addForm.systemName" placeholder="请输入系统名称"></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="remark">
            <el-input style="width: 45%;margin-bottom: 5px" v-model="addForm.remark" placeholder="添加系统描述" type="textarea"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="确认" name="2">
          <el-form-item label="当前操作员" prop="currOperatorName" style="margin-bottom: 10px">
            <el-input style="width: 20%" class="small-input" v-model="addForm.currOperatorName" placeholder="管理员user"
                      disabled></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('addForm')">立即创建</el-button>
            <!--            <el-button @click="resetForm('addForm')">重置</el-button>-->
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </el-dialog>
</template>

<script>
import {isSpecialCharacter} from "@/utils/validate";
export default {
  name: "addSystem",
  data() {
    var validateIsSpecialcharacter = (rule,input,callback) =>{
      if(isSpecialCharacter(input)) {
        callback(new Error('请不要输入除了“#”的特殊字符'))
      }
      else{
        callback();
      }
    }
    return {
      addSystemDialog: false,
      activeIndex:'0',

      coalIdSelected: '',
      options: [{
        id: 1,
        coal: "王家岭项目"
      }, {
        id: 2,
        coal: "张家岭项目"
      }, {
        id: 3,
        coal: "李家岭项目"
      }],
      addForm: {
        coalId: '',
        systemName: '',
        remark: ''
      },
      rules: {
        coalId: [
          {required: true, message: '请选择项目', trigger: 'blur'}
        ],
        systemName: [
          {required: true, message: '请输入系统名称', trigger: 'blur'},
          {validator:validateIsSpecialcharacter,trigger: 'blur'}
        ],
        remark: [
          {required: true, message: '请输入系统描述', trigger: 'blur'}
        ]
      }
    }
  },
  mounted() {
    this.addForm.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.addForm.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;
    this.initData();
  },
  methods: {
    init() {
      this.addSystemDialog = true;
    },
    initData() {
      this.addForm.coalId = this.global.coal_id
      this.getRequest('/dataList/getAllCoal/').then(resp=>{
        if (resp.data){
          this.options = resp.data;
          console.log(this.options)
        }
      });
    },
    addSystem() {
      this.$confirm(`确定进行创建[${this.addForm.systemName}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postRequest("/coalSystemManage/insertSystemInfoByCoalId", this.addForm).then((resp) => {
          if (resp.data) {
            // this.initData();
            this.$emit('update', true)
            this.addSystemDialog = false;
            this.activeIndex = "0"
            this.$refs.addForm.resetFields()
          }
        })
      }).catch(() => {})
    },
    submitForm(formName) {

      if(this.addForm.systemName === '' || this.addForm.remark === ''){
        this.$message("请完善系统信息，系统名称和描述不能为空！！！")
      }
      else{
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.addForm)
          this.addSystem()
        } else {
          return false;
        }
      })};

    },
    closeDialog() {
      this.addSystemDialog = false;
      this.$refs["addForm"].resetFields();
    }
  }
}
</script>

<style scoped>

</style>
